<template>
  <modal
    :modalId="id"
    :modalTitle="`Upload de Arquivos`"
    :load="false"
    :typeLg="`modal_lg`"
    @closeModal="closeModal"
  >
    <div class="tab-content" id="myTabContent">
      <tabs :tabs="tabList" />
      <uploadFile
        :class="`show active`"
        @uploads="files"
        :name.sync="formData.name"
        :file.sync="formData.file"
        :description.sync="formData.description"
        :trainingCategoryIds.sync="formData.trainingCategoryIds"
        :profiles.sync="formData.profiles"
        :id.sync="formData.id"
        :allProfiles.sync="formData.allProfiles"
        :fileName.sync="formData.fileName"
        :idDocumentUpload="
          tabList.find(s => s.tabName.includes('document')).tabName
        "
      />
      <uploadLink
        :formData="formData"
        :idLinkDirect="tabList.find(s => s.tabName.includes('link')).tabName"
      />
      <categoria-upload-vue
        v-if="$route.path === '/arquivos'"
        :trainingCategoryIds.sync="formData.trainingCategoryIds"
        :clear="load"
      />
    </div>
    <actions
      :nameButton="`Criar`"
      :disabled="load"
      @closeModal="closeModal"
      @submitform="submitUpload"
    />
  </modal>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
import HTTP from "@/api_system";

export default {
  props: ["itemData", "id"],
  components: {
    uploadFile: () => import("./tabs/uploadFile.vue"),
    uploadLink: () => import("./tabs/uploadLink.vue"),
    modal: () => import("@/components/modal/modalDefault.vue"),
    actions: () =>
      import("@/components/arquivos/files/acoes/botoes_rodape.vue"),
    categoriaUploadVue: () =>
      import("./tabs/uploadFileItens/categoriaUpload.vue"),
    tabs: () => import("@/components/tabs/modalTabs.vue"),
    HalfCircleSpinner
  },
  data() {
    return {
      load: false,
      file: null,
      formData: {
        name: "",
        file: "",
        description: "",
        link: "",
        trainingCategoryIds: "",
        profiles: [],
        id: "",
        fileName: "",
        allProfiles: false
      },
      tabList: [
        {
          description: "Documento",
          tabName: `${this.id}_document`
        },
        {
          description: "Link Direto",
          tabName: `${this.id}_link`
        }
      ]
    };
  },
  methods: {
    files(event) {
      // pegar a imagem
      this.formData.file = event.target.files[0];
      return event.target.files[0];
    },
    submitUpload() {
      this.load = true;
      let data = new FormData();

      let {
        description,
        link,
        name,
        file,
        allProfiles,
        trainingCategoryIds,
        profiles
      } = this.formData;

      if (!name) {
        this.toast("Nome do arquivo é obrigatório!", this.$toast.error);
        this.load = false;
        return;
      }

      if (!profiles.length) {
        allProfiles = true;
      }

      data.append("Name", name);
      data.append("File", file);
      data.append("Description", description);
      data.append("Url", link);
      data.append("Profiles", JSON.stringify(profiles));
      data.append("TrainingCategoryIds", JSON.stringify(trainingCategoryIds));
      data.append("AllProfiles", allProfiles);

      HTTP.post(`services/app/TrainingUpload/CreateOrEdit`, data, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(res => {
          this.toast("Arquivo criado com sucesso", this.$toast.success);
          this.load = false;
          this.$emit("returnFile");
          this.closeModal();
        })

        .catch(error => {
          this.load = false;
          this.toast(error.response.data.error.message, this.$toast.error);
        });
    },
    closeModal() {
      this.$emit("closeModal", this.id);
      this.formData = {
        name: "",
        file: "",
        description: "",
        link: "",
        trainingCategoryIds: this.formData.trainingCategoryIds,
        profiles: this.formData.profiles,
        nameFile: "",
        id: "",
        allProfiles: false
      };
    }
  },
  watch: {
    itemData: function() {
      if (this.itemData != undefined) {
        this.formData.trainingCategoryIds = this.itemData.trainingCategoryPermissions.map(
          t => t.trainingCategoryId
        );
        this.formData.profiles = this.itemData.trainingCategoryPermissions.map(
          t => t.profile
        );
      }
    }
  }
  // ,
  // mounted() {
  //   console.log(this.tabList);
  //   console.log(this.id);
  //   console.log(
  //     "is",
  //     this.tabList.find(s => s.tabName.includes("link"))
  //   );
  // }
};
</script>

<style scoped>
.menu,
#checkbox-menu,
#btnClose {
  cursor: pointer;
  position: relative;
  display: block;
  height: 22px;
  width: 30px;
  margin: 0;
}

#btnClose span {
  position: absolute;
  display: block;
  border-radius: 30px;
  background: orange;
  transition: 0.25s ease-in-out;
  width: 36px;
  border: 2px solid orange;
}

#btnClose span:nth-child(1) {
  transform: rotate(-45deg);
  top: 8px;
}

#btnClose span:nth-child(2) {
  opacity: 0;
}

#btnClose span:nth-child(3) {
  transform: rotate(45deg);
  top: 8px;
}

#modalUpload h1 {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #2474b2;
}

#modalUpload .main > div {
  display: flex;
  flex-direction: column;
}

#modalUpload .main .checkContainer {
  display: flex;
}

#modalUpload .main label {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #797984;
  cursor: pointer;
}

#modalUpload .main input {
  background: #ffffff;
  border: 1px solid #98c4e6;
  box-sizing: border-box;
  border-radius: 6px;
  height: 42px;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
}
</style>
